<template>
  <v-container fluid>
    <v-row>
      <Heading :title="$t('myProfile.TITLE')" />
      <v-col
        cols="12"
        sm="8"
        offset-sm="2"
      >
        <v-dialog
          v-model="dialog"
          max-width="400px"
        >
          <template v-slot:activator="{ on }">
            <v-col class="text-center">
              <v-btn
                small
                text
                color="secondary"
                class="btnChangePassword"
                v-on="on"
                @click="triggerChangePassword = true"
              >
                {{ $t('myProfile.CHANGE_MY_PASSWORD') }}
              </v-btn>
            </v-col>
          </template>
          <v-card>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(save)">
                <v-card-title>
                  <span class="text-h5">
                    {{ $t('myProfile.CHANGE_MY_PASSWORD') }}
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <template v-if="triggerChangePassword">
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|min:5"
                          >
                            <v-text-field
                              id="oldPassword"
                              key="oldPassword"
                              v-model="oldPassword"
                              name="oldPassword"
                              type="password"
                              :label="$t('myProfile.CURRENT_PASSWORD')"
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              autocomplete="off"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|min:5"
                            vid="newPassword"
                          >
                            <v-text-field
                              id="newPassword"
                              key="newPassword"
                              ref="password"
                              v-model="newPassword"
                              name="newPassword"
                              type="password"
                              :label="$t('myProfile.NEW_PASSWORD')"
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              autocomplete="off"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|min:5|confirmed:newPassword"
                          >
                            <v-text-field
                              id="confirmPassword"
                              key="confirmPassword"
                              v-model="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              :label="$t('myProfile.CONFIRM_NEW_PASSWORD')"
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              autocomplete="off"
                            />
                          </ValidationProvider>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red lighten3"
                    text
                    @click="close"
                  >
                    {{ $t('dataTable.CANCEL') }}
                  </v-btn>
                  <SubmitButton
                    id="updatePassword"
                    text
                    :button-text="$t('dataTable.SAVE')"
                    color="green"
                  />
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|email"
                  >
                    <v-text-field
                      id="email"
                      v-model="email"
                      name="email"
                      type="email"
                      :label="$t('myProfile.EMAIL')"
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      disabled
                      autocomplete="off"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-text-field
                      id="name"
                      v-model="name"
                      name="name"
                      type="text"
                      :label="$t('myProfile.NAME')"
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      autocomplete="off"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-text-field
                      id="phone"
                      v-model="phone"
                      name="phone"
                      type="tel"
                      :label="$t('myProfile.PHONE')"
                      clear-icon="mdi-close"
                      clearable
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      autocomplete="off"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-autocomplete
                      id="city"
                      v-model="city"
                      name="city"
                      :label="$t('myProfile.CITY')"
                      :search-input.sync="searchInput"
                      :items="allCities"
                      clearable
                      clear-icon="mdi-close"
                      :no-data-text="$t('myProfile.NO_RESULTS_FOUND')"
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      autocomplete="off"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-text-field
                      id="country"
                      v-model="country"
                      name="country"
                      type="text"
                      :label="$t('myProfile.COUNTRY')"
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      autocomplete="off"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="url"
                  >
                    <v-text-field
                      id="urlTwitter"
                      v-model="urlTwitter"
                      name="urlTwitter"
                      type="url"
                      label="Twitter"
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      autocomplete="off"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="url"
                  >
                    <v-text-field
                      id="urlGitHub"
                      v-model="urlGitHub"
                      name="urlGitHub"
                      type="url"
                      label="GitHub"
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      autocomplete="off"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col class="text-center mt-5">
                  <SubmitButton
                    :button-text="$t('myProfile.SAVE')"
                    custom-class="btnSave"
                  />
                </v-col>
              </v-row>
            </v-container>
          </form>
        </ValidationObserver>
      </v-col>
      <ErrorMessage />
      <SuccessMessage />
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    metaInfo () {
      return {
        title: this.$store.getters.appTitle,
        titleTemplate: `${this.$t('myProfile.TITLE')} - %s`,
      }
    },
    data () {
      return {
        dialog: false,
        triggerChangePassword: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        searchInput: '',
      }
    },
    computed: {
      name: {
        get () {
          return this.$store.state.profile.profile.name
        },
        set (value) {
          const data = {
            key: 'name',
            value,
          }
          this.addProfileData(data)
        },
      },
      email () {
        return this.$store.state.profile.profile.email
      },
      phone: {
        get () {
          return this.$store.state.profile.profile.phone
        },
        set (value) {
          const data = {
            key: 'phone',
            value,
          }
          this.addProfileData(data)
        },
      },
      allCities () {
        return this.$store.state.cities.allCities
      },
      city: {
        get () {
          return this.$store.state.profile.profile.city
        },
        set (value) {
          const data = {
            key: 'city',
            value,
          }
          this.addProfileData(data)
        },
      },
      country: {
        get () {
          return this.$store.state.profile.profile.country
        },
        set (value) {
          const data = {
            key: 'country',
            value,
          }
          this.addProfileData(data)
        },
      },
      urlTwitter: {
        get () {
          return this.$store.state.profile.profile.urlTwitter
        },
        set (value) {
          const data = {
            key: 'urlTwitter',
            value,
          }
          this.addProfileData(data)
        },
      },
      urlGitHub: {
        get () {
          return this.$store.state.profile.profile.urlGitHub
        },
        set (value) {
          const data = {
            key: 'urlGitHub',
            value,
          }
          this.addProfileData(data)
        },
      },
    },
    methods: {
      ...mapActions([
        'changeMyPassword',
        'getProfile',
        'getAllCities',
        'addProfileData',
        'saveProfile',
      ]),
      async submit () {
        await this.saveProfile({
          name: this.name,
          phone: this.phone,
          city: this.city,
          country: this.country,
          urlTwitter: this.urlTwitter,
          urlGitHub: this.urlGitHub,
        })
      },
      close () {
        this.triggerChangePassword = false
        this.dialog = false
      },
      async save () {
        try {
          await this.changeMyPassword({
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          })
          this.oldPassword = ''
          this.newPassword = ''
          this.confirmPassword = ''
          this.triggerChangePassword = false
          this.close()
          return
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.oldPassword = ''
          this.newPassword = ''
          this.confirmPassword = ''
          this.triggerChangePassword = false
          this.close()
        }
      },
    },
    async mounted () {
      await this.getProfile()
      await this.getAllCities()
    },
  }
</script>
